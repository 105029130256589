





























































































































































































































































































































































































































































.step-title {
  color: var(--v-text-base);
}
.buy-device {
  .w-200 .v-input__slot, .w-200 .v-text-field{
    width: 200px !important;
  }
}
